"use strict";

jQuery(document).ready(function (jQuery) {
  if (document.querySelector('.test') !== null) {
    new Glider(document.querySelector('.test'), {
      draggable: true,
      dots: false,
      arrows: {
        prev: '.slider-prev-arrow',
        next: '.slider-next-arrow'
      },
      responsive: [{
        breakpoint: 900,
        settings: {
          slidesToScroll: 1,
          slidesToShow: 2.5
        }
      }, {
        breakpoint: 375,
        settings: {
          slidesToShow: 1.2,
          slidesToScroll: 1
        }
      }]
    });
  }
});