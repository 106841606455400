"use strict";

jQuery(document).ready(function (jQuery) {
  var menuBtn = document.querySelector('.menu-btn');
  var headerLogo = document.querySelector('.logo');
  var nav = document.querySelector('nav');
  var lineOne = document.querySelector(' .menu-btn .line--1');
  var lineTwo = document.querySelector(' .menu-btn .line--2');
  var lineThree = document.querySelector(' .menu-btn .line--3');
  var link = document.querySelector('nav .nav-links');

  // Configure/customize these variables.

  menuBtn.addEventListener('click', function () {
    nav.classList.toggle('nav-open');
    lineOne.classList.toggle('line-cross');
    lineTwo.classList.toggle('line-fade-out');
    lineThree.classList.toggle('line-cross');
    headerLogo.classList.toggle('open');
    link.classList.toggle('fade-in');
  });

  // Initialize Magnific Popup
  $('.magnific-popup').magnificPopup({
    type: 'image',
    mainClass: ' mfp-with-zoom',
    removalDelay: 300,
    gallery: {
      enabled: true
    }
  });
  jQuery(document).foundation();
  // let nav = jQuery("nav#site-navigation");
  // jQuery('button#openMenu').click(function () {
  //     nav.addClass("open");
  // });
  //
  // jQuery('button#primary-mobile-menu').click(function () {
  //     nav.removeClass("open");
  // });

  jQuery('.share').click(function () {
    jQuery('.share-icons').slideToggle();
  });
  var sociallineOne = document.querySelector(' .social-menu .menu-btn .line--1');
  var sociallineTwo = document.querySelector(' .social-menu .menu-btn .line--2');
  var sociallineThree = document.querySelector(' .social-menu .menu-btn .line--3');
  jQuery('.social-menu .menu-btn ').click(function () {
    jQuery('.items').slideToggle();
    jQuery('.social-menu').toggleClass('open');
    sociallineOne.classList.toggle('line-cross');
    sociallineTwo.classList.toggle('line-fade-out');
    sociallineThree.classList.toggle('line-cross');
  });
  var ul = jQuery("nav#site-navigation ul li ul");
  var li = jQuery("nav#site-navigation ul li ul li");
  if (ul.hasClass("children")) {
    ul.parent(li).addClass("hasChildren");
  }
  if (li.hasClass("hasChildren")) {
    jQuery("li.hasChildren a").append("<a href='javascript:void(0)' class='expandChild' title='Child item'></a>");
  }
  $("#login").click(function () {
    $("#login-buttons").toggleClass('open');
    $(this).toggleClass('open');
  });
  var didScroll;
  var lastScrollTop = 0;
  var delta = 5;
  var navbarHeight = jQuery('header').outerHeight();
  jQuery(window).scroll(function (event) {
    didScroll = true;
  });
  setInterval(function () {
    if (didScroll) {
      hasScrolled();
      didScroll = false;
    }
  }, 250);
  function hasScrolled() {
    var st = jQuery(window).scrollTop();

    // Make sure they scroll more than delta
    if (Math.abs(lastScrollTop - st) <= delta) return;

    // If they scrolled down and are past the navbar, add class .nav-up.
    // This is necessary so you never see what is "behind" the navbar.
    if (st > lastScrollTop && st > navbarHeight) {
      // Scroll Down
      jQuery('header').removeClass('nav-down').addClass('nav-up');
    } else {
      // Scroll Up
      if (st + jQuery(window).height() < jQuery(document).height()) {
        jQuery('header').removeClass('nav-up').addClass('nav-down');
      }
    }
    lastScrollTop = st;
  }
  var distance = $('header').offset().top;
  $(window).scroll(function () {
    if ($(this).scrollTop() >= distance) {
      jQuery('header').addClass('not-on-top').removeClass('on-top');
      jQuery('#page').addClass('not-on-top').removeClass('on-top');
    } else {
      jQuery('header').addClass('on-top').removeClass('not-on-top');
      jQuery('#page').addClass('on-top').removeClass('not-on-top');
    }
  });
  jQuery(document).ready(function () {
    jQuery('.open-search-from').click(function () {
      jQuery('section').addClass("open-search");
      jQuery('body').addClass("search-main");
    });
    jQuery('#deleteSearch').click(function () {
      jQuery('section').removeClass("open-search");
      jQuery('body').removeClass("search-main");
    });
  });
  var header = document.querySelector('.site-header');
  var menuItems = document.querySelectorAll('.menu-item-has-children');
  var timeout;
  menuItems.forEach(function (item) {
    item.addEventListener('mouseover', function () {
      clearTimeout(timeout); // Clear the timeout if mouseover happens before the timeout
      header.classList.add('mega-menu');
    });
    item.addEventListener('mouseout', function () {
      timeout = setTimeout(function () {
        header.classList.remove('mega-menu');
      }, 500); // 5000 milliseconds = 5 seconds
    });
  });

  var btn = $('#back-to-top');
  $(window).scroll(function () {
    if ($(window).scrollTop() > 300) {
      btn.addClass('show');
    } else {
      btn.removeClass('show');
    }
  });
  btn.on('click', function (e) {
    e.preventDefault();
    $('html, body').animate({
      scrollTop: 0
    }, '300');
  });
  $('.popup-youtube, .popup-vimeo, .popup-gmaps').magnificPopup({
    // disableOn: 700,
    type: 'iframe',
    mainClass: 'mfp-fade',
    removalDelay: 160,
    preloader: false,
    fixedContentPos: false,
    iframe: {
      patterns: {
        youtube: {
          index: 'youtube.com',
          id: 'v=',
          src: 'https://www.youtube.com/embed/%id%'
        }
      },
      srcAction: 'iframe_src'
    }
  });
});
jQuery.event.special.touchstart = {
  setup: function setup(_, ns, handle) {
    this.addEventListener('touchstart', handle, {
      passive: !ns.includes('noPreventDefault')
    });
  }
};
jQuery.event.special.touchmove = {
  setup: function setup(_, ns, handle) {
    this.addEventListener('touchmove', handle, {
      passive: !ns.includes('noPreventDefault')
    });
  }
};
window.addEventListener("scroll", function () {
  var distance = window.scrollY;
  document.querySelector(".parallax-window").style.transform = "translateY(".concat(distance * 0.3, "px)");
});