"use strict";

jQuery(document).ready(function (jQuery) {
  if (document.querySelector('.vacancy-slider-container') !== null) {
    new Glider(document.querySelector('.vacancy-slider-container'), {
      draggable: false,
      dots: false,
      arrows: {
        prev: '.vacancy-slider-arrow-prev',
        next: '.vacancy-slider-arrow-next'
      },
      responsive: [{
        breakpoint: 900,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1
        }
      }, {
        breakpoint: 375,
        settings: {
          slidesToShow: 1.2,
          slidesToScroll: 1
        }
      }]
    });
  }
});