"use strict";

jQuery(document).ready(function (jQuery) {
  if (document.querySelector('.partner-container') !== null) {
    new Glider(document.querySelector('.partner-container'), {
      dots: false,
      arrows: {
        prev: '.partner-container-slider-arrow-prev',
        next: '.partner-container-slider-arrow-next'
      },
      responsive: [{
        breakpoint: 900,
        settings: {
          slidesToShow: 5.5,
          slidesToScroll: 1
        }
      }, {
        breakpoint: 300,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1
        }
      }]
    });
  }
});