"use strict";

jQuery(document).ready(function (jQuery) {
  if (document.querySelector('.storyslider') !== null) {
    new Glider(document.querySelector('.storyslider'), {
      draggable: false,
      dots: false,
      arrows: {
        prev: '.story-slider-arrow-prev',
        next: '.story-slider-arrow-next'
      },
      responsive: [{
        breakpoint: 900,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      }, {
        breakpoint: 375,
        settings: {
          slidesToShow: 1.2,
          slidesToScroll: 1
        }
      }]
    });
  }
});