"use strict";

jQuery(document).ready(function (jQuery) {
  if (document.querySelector('.cards-container') !== null) {
    new Glider(document.querySelector('.cards-container'), {
      dots: false,
      slidesToShow: 5,
      slidesToScroll: 1,
      arrows: {
        prev: '.cards-container-slider-arrow-prev',
        next: '.cards-container-slider-arrow-next'
      },
      responsive: [{
        breakpoint: 1400,
        settings: {
          slidesToShow: 5
        }
      }, {
        breakpoint: 900,
        settings: {
          slidesToShow: 3
        }
      }, {
        breakpoint: 300,
        settings: {
          slidesToShow: 1.3
        }
      }]
    });
  }
});