"use strict";

jQuery(document).ready(function (jQuery) {
  if (document.querySelector('.number') !== null) {
    new Glider(document.querySelector('.number'), {
      dots: false,
      slidesToShow: 4,
      arrows: {
        prev: '.card-slider-arrow-prev',
        next: '.card-slider-arrow-next'
      },
      responsive: [{
        breakpoint: 900,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1
        }
      }, {
        breakpoint: 575,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1
        }
      }]
    });
  }
});
$(function ($, win) {
  $.fn.inViewport = function (cb) {
    return this.each(function (i, el) {
      function visPx() {
        var H = $(this).height(),
          r = el.getBoundingClientRect(),
          t = r.top,
          b = r.bottom;
        return cb.call(el, Math.max(0, t > 0 ? H - t : b < H ? b : H));
      }
      visPx();
      $(win).on("resize scroll", visPx);
    });
  };
}(jQuery, window));
jQuery(function ($) {
  // DOM ready and $ in scope

  $(".stat").inViewport(function (px) {
    // Make use of the `px` argument!!!
    // if element entered V.port ( px>0 ) and
    // if prop initNumAnim flag is not yet set
    //  = Animate numbers
    if (px > 0 && !this.initNumAnim) {
      this.initNumAnim = true; // Set flag to true to prevent re-running the same animation
      $(this).prop('Counter', 0).animate({
        Counter: $(this).text()
      }, {
        duration: 2500,
        step: function step(now) {
          $(this).text(parseFloat(now.toFixed(0)));
        }
      });
    }
  });
});