"use strict";

jQuery(document).ready(function (jQuery) {
  if (document.querySelector('.employee-slide-0') !== null) {
    new Glider(document.querySelector('.employee-slide-0'), {
      dots: false,
      slidesToShow: 6,
      arrows: {
        prev: '.employee-slide-one-arrow-prev',
        next: '.employee-slide-one-arrow-next'
      },
      responsive: [{
        breakpoint: 900,
        settings: {
          slidesToShow: 11,
          slidesToScroll: 1
        }
      }, {
        breakpoint: 575,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 1
        }
      }]
    });
  }
  if (document.querySelector('.employee-slide-1') !== null) {
    new Glider(document.querySelector('.employee-slide-1'), {
      dots: false,
      slidesToShow: 6,
      arrows: {
        prev: '.employee-slide-two-arrow-prev',
        next: '.employee-slide-two-arrow-next'
      },
      responsive: [{
        breakpoint: 900,
        settings: {
          slidesToShow: 11,
          slidesToScroll: 1
        }
      }, {
        breakpoint: 575,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 1
        }
      }]
    });
  }
  if (document.querySelector('.employee-slide-2') !== null) {
    new Glider(document.querySelector('.employee-slide-2'), {
      dots: false,
      slidesToShow: 6,
      arrows: {
        prev: '.employee-slide-three-arrow-prev',
        next: '.employee-slide-three-arrow-next'
      },
      responsive: [{
        breakpoint: 900,
        settings: {
          slidesToShow: 11,
          slidesToScroll: 1
        }
      }, {
        breakpoint: 575,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 1
        }
      }]
    });
  }
});