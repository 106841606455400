"use strict";

jQuery(document).ready(function (jQuery) {
  if (document.querySelector('.post-container') !== null) {
    new Glider(document.querySelector('.post-container'), {
      dots: false,
      arrows: {
        prev: '.post-container-slider-arrow-prev',
        next: '.post-container-slider-arrow-next'
      },
      responsive: [{
        breakpoint: 900,
        settings: {
          slidesToShow: 2.5,
          slidesToScroll: 1
        }
      }, {
        breakpoint: 300,
        settings: {
          slidesToShow: 1.3,
          slidesToScroll: 1
        }
      }]
    });
  }
});